import $ from "jquery";

$( document ).on('turbolinks:load', function() {
  if ($('.admin_dm_provider_form').length) {
    $('#billingModal').on('hidden.bs.modal', function (e) {
      // reseteamos form billing
      $("#billing_commune_id").val("");
      $("#billing_commune_id").trigger("change");

      // guardamos el billing seleccionado para no perderlo al actualizar
      let selectedValue = $('#dm_provider_billing_id').val();
      $("#dm_provider_billing_id").empty();

      // consultamos por lista actualizada para select de billings
      let url = $("#dm_provider_billing_id").data("url");
      $.get(url, function(data) {
        // Si no había nada seleccionado, dejamos el valor por defecto
        if(selectedValue === ""){
          $('#dm_provider_billing_id').append("<option selected='selected'>Seleccionar facturación</option>");
        }
        data.options.forEach(function( option ){
          // Si había una opción seleccionada, la marcamos como tal
          if(option[1] === parseInt(selectedValue)){
            $('#dm_provider_billing_id').append("<option selected value="+option[1]+">"+option[0]+"</option>");
          }
          else{
            $('#dm_provider_billing_id').append("<option value="+option[1]+">"+option[0]+"</option>");
          }
        });
      });
    })

    // Manejar el envío del formulario
    $('#create_billing_form').on('ajax:success', function () {
      $('#billingModal').modal('hide');
    });
  }
});
